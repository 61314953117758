.order
  margin-bottom: 0 !important
  padding: 0

.form-label label
  font-size: 1rem

.pad-left-20
  padding-left: 20px


.item-container
  display: grid
  grid-template-rows: auto auto
  grid-gap: 15px


.item-container-summary
  p
    font-size: 1rem

  p:nth-child(2)
    font-size: 0.85rem